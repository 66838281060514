<template>
  <div class="w-full flex flex-col gap-1">
    <div class="w-full flex flex-col gap-1">
      <div class="relative w-full">
        <div
          v-if="showRank && release.rankSite"
          class="absolute bottom-0 left-0 inline-flex flex-col items-center bg-blue-400"
        >
          <div
            class="w-full flex flex-col items-center bg-black uppercase text-xs font-thin tracking-widest p-0.5 px-2"
          >
            Rank
          </div>
          <div
            class="w-full flex flex-col items-center font-bold px-2 text-xl"
            style="text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5)"
          >
            {{ release.rankSite }}
          </div>
        </div>

        <div
          class="absolute inline-flex flex-col items-center"
          style="bottom: 0.25rem; right: 0.25rem"
        >
          <UButton
            v-if="showFavoriteButton"
            size="xl"
            color="white"
            square
            variant="solid"
            @click="handleFavorite"
          >
            <font-awesome-icon icon="fa-regular fa-heart-circle-plus" />
          </UButton>
        </div>

        <div
          class="cursor-pointer w-full overflow-hidden"
          @mouseenter="handleMouseEnter"
          @mouseleave="handleMouseLeave"
          @click="handleClick"
        >
          <NuxtLink v-if="!hovering" :to="releaseUrl" @click="handleClick">
            <NuxtImg
              v-slot="{ src, isLoaded, imgAttrs }"
              class="w-full"
              custom
              :class="aspectRatio"
              :src="release.thumbUrl"
              alt="Thumbnail"
              loading="lazy"
            >
              <img v-if="isLoaded" v-bind="imgAttrs" :src="src" />
              <img
                v-else
                src="https://cdn-images.r1.cdn.pornpros.com/content/placeholders/sample_475x268.jpg?imgw=475&imgh=268"
                alt="placeholder"
              />
            </NuxtImg>
          </NuxtLink>
          <video
            v-else
            class="w-full object-cover object-center"
            :class="aspectRatio"
            :src="release.thumbVideoUrl"
            muted
            loop
            autoplay
            preload="none"
            playsinline
          />
        </div>
      </div>

      <div
        v-if="showDetails"
        class="w-full flex flex-row items-stretch gap-2 p-1"
      >
        <SponsorCard
          v-if="
            currentSite.slug === 'pornplus' &&
            release.sponsor &&
            release.sponsor.hasImages
          "
          :sponsor="release.sponsor"
          class="h-[55px]"
        />

        <div class="w-full flex flex-row justify-between">
          <div class="w-full flex flex-col">
            <NuxtLink
              :to="releaseUrl"
              class="text-white leading-tight text-xl"
              @click="handleClick"
            >
              <strong>{{ release.title }}</strong>
            </NuxtLink>

            <p
              class="whitespace-nowrap overflow-hidden text-ellipsis max-w-[300px]"
            >
              <span
                v-for="(actor, i) in sortedActors"
                :key="`release-card-actor-${i}`"
              >
                <NuxtLink
                  :to="`${linkPrefix}/models/${actor.cached_slug}`"
                  class="inline text-lg"
                >
                  {{ actor.name }}
                </NuxtLink>
                <span v-if="i < release.actors.length - 1">,&nbsp;</span>
              </span>
            </p>
            <p v-if="showReleaseDate" class="text-lg text-[#6d6d6d]">
              {{ format(release.releasedAt, "MM/dd/yyyy") }}
            </p>

            <NuxtLink
              v-if="sponsorLogo(release.sponsor)"
              :to="`${linkPrefix}/series/${release.sponsor.cachedSlug}`"
              class="text-lg text-[#6d6d6d]"
            >
              <img class="max-h-4 mt-1" :src="sponsorLogo(release.sponsor)" />
            </NuxtLink>
          </div>
          <div v-if="showRating" class="flex flex-col gap-1">
            <div class="flex flex-row items-center justify-end gap-1">
              <font-awesome-icon
                icon="fa-solid fa-star"
                class="text-xs text-blue-600"
              />
              <span class="text-blue-600">
                {{ (release.ratingsDecimal ?? 0).toFixed(1) }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "ReleaseCard",
  }
</script>

<script setup>
  import { format } from "date-fns"

  const props = defineProps({
    release: Object,
    showRank: {
      type: Boolean,
      default: false,
    },
    showFavoriteButton: {
      type: Boolean,
      default: false,
    },
    showDetails: {
      type: Boolean,
      default: true,
    },
    showRating: {
      type: Boolean,
      default: true,
    },
    vertical: {
      type: Boolean,
      default: false,
    },
  })

  const currentSite = inject("currentSite")
  const { sponsorLogo } = useSiteSettings(currentSite.value)
  const { inMembersArea } = useMembersArea()
  const { isMobile, isDesktop } = useResponsive()
  const linkPrefix = inMembersArea.value ? "/members" : ""
  const aspectRatio = props.vertical ? "aspect-[417/741]" : "aspect-[475/268]"

  const releaseUrl = computed(() => {
    if (inMembersArea.value) {
      return `${linkPrefix}/video/${props.release.cachedSlug}`
    } else {
      return `/video/${props.release.cachedSlug}`
    }
  })

  // TODO: Plugin, composable?
  const sortedActors = computed(() => {
    return props.release.actors.sort((a, b) => {
      if (a.gender === "girl" && b.gender === "guy") return -1
      if (a.gender === "guy" && b.gender === "girl") return 1
      return 0
    })
  })

  const hovering = ref(false)

  const handleMouseEnter = () => {
    if (props.release.thumbVideoUrl) {
      hovering.value = true
    }
  }

  const handleMouseLeave = () => {
    if (props.release.thumbVideoUrl) {
      hovering.value = false
    }
  }

  const handleClick = async (e) => {
    let url = currentSite.value.natsJoinUrl

    e.preventDefault()

    if (inMembersArea.value) {
      if (currentSite.value.id == props.release.siteId) {
        url = `${linkPrefix}/video/${props.release.cachedSlug}`
        await navigateTo(url, { external: true })
      } else {
        // TODO: Add missing channels/packs logic
        // else if the release is from a channel/pack, redirect to the channel/pack player.
        const { data: items } = await useApi(
          `/api/members/grant_guest_access?sid=${props.release.site.slug}`,
        )
        if (items.value) {
          window.location.href = items.value.url
          return
        }
      }
    } else {
      if (isMobile.value && currentSite.value.showTrailersOnMobile) {
        url = `/video/${props.release.cachedSlug}`
      } else if (isDesktop.value && currentSite.value.showTrailersOnDesktop) {
        url = `/video/${props.release.cachedSlug}`
      }
      await navigateTo(url, { external: true })
    }
  }

  const showReleaseDate = computed(() => {
    const ninetyDaysAgo = new Date() - 90 * 24 * 60 * 60 * 1000
    return props.release.releasedAt > ninetyDaysAgo
  })
</script>
